/* If you need to add @import statements, do so up here */

@import "jit-refresh.css"; /* triggers frontend rebuilds */

/* Set up Tailwind imports */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .vtab-bar {
    @apply flex flex-col text-xs text-gray-500 mb-2 p-0.5 font-medium
  }

  .tab-bar {
    @apply flex flex-row text-xs text-gray-500 mb-2 p-0.5 font-medium bg-gray-200 rounded-lg
  }

  .nav-section {
    @apply w-full font-thin uppercase flex items-center px-4 py-2 my-1;
  }

  .nav-section-label{
    @apply grow text-sm font-normal text-left hover:text-blue-700;
  }

  .nav-item {
    @apply w-auto font-thin uppercase flex items-center m-1.5 p-2 rounded-lg border border-transparent hover:bg-gray-50 hover:shadow-md hover:border hover:border-gray-400;
  }

  .nav-item-label {
    @apply flex mx-2 text-xs font-normal text-left
  }

  .btn-primary {
    @apply inline-flex p-2 space-x-2 rounded-md items-center text-sm text-white bg-blue-600 border border-blue-700 hover:bg-blue-700 focus:ring-2 focus:outline-1 focus:ring-blue-400 cursor-pointer;
  }

  .btn-primary-outline {
    @apply px-4 py-2 w-auto rounded-md inline-flex text-blue-500 border border-blue-300 text-center hover:bg-blue-600 hover:text-gray-50 focus:ring-2 focus:outline-1 focus:ring-blue-400 cursor-pointer;
  }

  .btn-secondary {
    @apply inline-flex p-2 space-x-2 rounded-md items-center text-sm text-gray-500 border border-gray-400 hover:bg-gray-200 hover:text-gray-700 focus:ring-2 focus:outline-1 focus:ring-gray-300 cursor-pointer;
  }

  .btn-delete-outline {
    @apply inline-flex px-4 py-2 w-auto rounded-md items-center text-sm text-red-500 border border-red-300 hover:bg-red-700 hover:text-gray-50 focus:ring-2 focus:outline-1 focus:ring-red-300 cursor-pointer;
  }

  .btn-secondary-sm {
    @apply inline-flex items-center p-1 rounded-md text-gray-500 hover:bg-gray-100 hover:text-blue-600 cursor-pointer;
  }

  .btn-secondary-sm-active {
    @apply inline-flex items-center p-1 space-x-1 rounded-md hover:bg-gray-100 hover:text-blue-600 text-blue-500 border bg-gray-100 cursor-pointer;
  }

  .btn-action-primary {
    @apply inline-flex items-center text-gray-500 cursor-pointer hover:bg-gray-200 rounded-full p-2
  }

  .btn-link {
    @apply inline-flex text-sm font-semibold leading-6 text-blue-600 underline underline-offset-4 hover:text-gray-900
  }

  .btn-link-small {
    @apply text-xs text-gray-600 hover:text-gray-800 hover:underline hover:underline-offset-4
  }

  .form-group label {
    @apply flex mb-2 text-sm font-medium text-gray-500;
  }

  .form-group input,
  .form-group select,
  .form-group area {
    @apply appearance-none rounded-md relative block w-full px-3 py-1.5 bg-gray-100 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm invalid:border-orange-500 invalid:text-orange-600 focus:invalid:border-orange-500 focus:invalid:ring-orange-500;
  }

  .form-group input[type='file'] {
    @apply border-transparent bg-transparent px-0 text-xs space-x-2 text-gray-700 cursor-pointer
  }
}

.stop-scrolling {
  height: 100%;
  overflow: hidden;
}